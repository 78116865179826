<template>
  <div class="my-3 container user-profile">
    <h3 class="my-3">
      {{ $t("accountInfo") }}
    </h3>
    <div id="update-image" class="row bg-light card my-3 p-3">
      <div class="col-12">
        <h3>{{ $t("profileImageTitle") }}</h3>
        <p>{{ $t("profileImageDescription") }}</p>
      </div>
      <div class="col-12 d-flex align-items-center my-4 justify-content-center">
        <span class="position-relative" @click="$refs.fileInput.click()">
          <img src="/images/edit.svg" class="edit-icon" width="35" alt="" />
          <img
            class="profile-avatar"
            :src="userImage"
            @error="(e) => (e.target.src = getSettings.defaultImage)"
            :alt="user.fullname"
          />
        </span>
        <button
          v-if="imgFile !== null"
          @click="UPDATE_PROFILE_IMAGE()"
          class="mx-3 btn donate btn-pill"
        >
          {{ $t("upload") }}
        </button>
        <input
          accept="image/*"
          type="file"
          @change="SET_IMAGE"
          class="d-none"
          ref="fileInput"
        />
      </div>
    </div>
    <hr />
    <div id="update-information" class="row bg-light card my-3 p-3">
      <div class="col-12">
        <h3>{{ $t("profileUpdateTitle") }}</h3>
        <p>{{ $t("profileUpdateDescription") }}</p>
      </div>
      <div class="col-12">
        <section class="vid-title-sec">
          <!-- is active geldiğinde içeri gönder -->
          <div class="container">
            <form @submit.prevent="UPDATE_PROFILE">
              <div class="vid-title">
                <h2 class="title-hd">{{ $t("enterFullname") }} *</h2>
                <div class="form_field">
                  <input
                    type="text"
                    required
                    v-model="user.fullname"
                    maxlength="100"
                    :placeholder="$t('addHereMax100Char')"
                  />
                </div>
              </div>
              <div class="vid-title mt-3">
                <h2 class="title-hd">{{ $t("enterPhone") }} *</h2>
                <div class="form_field">
                  <input
                    type="text"
                    pattern="\+[0-9]{0,14}$"
                    :title="$t('invalidPhoneRegex')"
                    required
                    v-model="user.phone"
                    maxlength="15"
                    :placeholder="$t('phonePlaceholder')"
                  />
                </div>
              </div>
              <div class="vid-title mt-3">
                <h2 class="title-hd">{{ $t("email") }} *</h2>
                <div class="form_field">
                  <input
                    type="email"
                    required
                    v-model="user.email"
                    maxlength="100"
                    :placeholder="$t('addHereMax100Char')"
                  />
                </div>
                <div class="mt-2 alert alert-warning">
                  <h4 class="mb-2">{{ $t("importantDescription") }}</h4>
                  <p>* {{ $t("emailChangeDescription") }}</p>
                  <p>* {{ $t("emailChangeDescription2") }}</p>
                  <p>
                    <strong>* {{ $t("emailChangeDescription3") }}</strong>
                  </p>
                </div>
              </div>
              <div class="mt-3 text-right">
                <button type="submit" class="btn donate btn-pill">
                  {{ $t("save") }}
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
    <hr />
    <div id="update-password" class="row bg-light card my-3 p-3">
      <div class="col-12">
        <h3>{{ $t("changePasswordTitle") }}</h3>
        <p>{{ $t("changePasswordDescription") }}</p>
      </div>
      <div class="col-12">
        <section class="vid-title-sec">
          <!-- is active geldiğinde içeri gönder -->
          <div class="container">
            <form @submit.prevent="UPDATE_CHANGE_PASWORD">
              <div class="vid-title">
                <h2 class="title-hd">{{ $t("oldPassword") }} *</h2>
                <div class="form_field">
                  <input
                    type="password"
                    required
                    v-model="userPassword.oldPassword"
                    maxlength="100"
                    :placeholder="$t('addHereMax100Char')"
                  />
                </div>
              </div>
              <div class="vid-title mt-3">
                <h2 class="title-hd">{{ $t("newPassword") }} *</h2>
                <div class="form_field">
                  <input
                    type="password"
                    required
                    minlength="5"
                    v-model="userPassword.newPassword"
                    maxlength="100"
                    :placeholder="$t('addHereMax100Char')"
                  />
                </div>
              </div>
              <div class="vid-title mt-3">
                <h2 class="title-hd">{{ $t("confirmNewPassword") }} *</h2>
                <div class="form_field">
                  <input
                    type="password"
                    required
                    minlength="5"
                    :title="$t('validMessage.doesntMatchPasswords')"
                    v-model="userPassword.confirmNewPassword"
                    maxlength="100"
                    :placeholder="$t('addHereMax100Char')"
                  />
                </div>
                <div
                  v-if="
                    userPassword.confirmNewPassword !== userPassword.newPassword
                  "
                >
                  <small class="text-danger">
                    * {{ $t("validMessage.doesntMatchPasswords") }}
                  </small>
                </div>
              </div>
              <div class="mt-3 text-right">
                <button type="submit" class="btn donate btn-pill">
                  {{ $t("save") }}
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
    <hr />
    <div
      id="rezervation"
      class="row bg-light card my-3 p-3"
      v-if="getSettings.isSeances === 1"
    >
      <div class="col-12">
        <div class="text-center">
          <img width="100" src="/images/rez-logo.png" alt="" />
        </div>
        <h3>
          {{ $t("rezInfoTitle") }}
        </h3>
        <p>{{ $t("rezInfoDescription") }}</p>
        <p v-if="getRezConnection">{{ $t("rezInfoDescription2") }}</p>
      </div>
      <div
        class="col-12 changed-video-image justify-content-start align-items-center d-flex"
        :class="{ ' not-active': !getRezConnection }"
      >
        <img
          :src="`/images/${getRezConnection ? 'success' : 'danger'}.svg`"
          width="45"
          class="mr-3"
          alt=""
        />
        <span class="message-text-of-alert" v-if="getRezConnection">{{
          $t("connectionIsSucceed")
        }}</span>
        <strong class="message-text-of-alert" v-else>{{
          $t("connectionToRezzApp")
        }}</strong>
        <div v-if="!getRezConnection" class="mt-4 text-center w-100">
          <button @click="openWindow()" class="btn btn-pill btn-dark">
            {{ $t("maketoConnectionToRezz") }}
          </button>
        </div>
      </div>
      <div
        v-if="getRezConnection"
        class="col-12 col-md-6 col-lg-4 col-xl-3 mt-4"
      >
        <label class="info-title">{{ $t("companyName") }}</label>
        <h6 class="mt-2">{{ getRezCopmanyInformation.Name }}</h6>
      </div>
      <div
        v-if="getRezConnection"
        class="col-12 col-md-12 col-lg-8 col-xl-9 mt-4"
      >
        <label class="info-title">{{ $t("companyAddress") }}</label>
        <h6 class="mt-2">{{ getRezCopmanyInformation.Address }}</h6>
      </div>
      <div
        v-if="getRezConnection"
        class="col-12 col-md-6 col-lg-4 col-xl-3 mt-4"
      >
        <label class="info-title">{{ $t("connectionLink") }}</label>
        <h6 class="mt-2">{{ getRezCopmanyInformation.Domain }}</h6>
      </div>
      <div
        v-if="getRezConnection"
        class="col-12 col-md-6 col-lg-4 col-xl-3 mt-4"
      >
        <label class="info-title">{{ $t("category") }}</label>
        <h6 class="mt-2">{{ getRezCopmanyInformation.Department.Name }}</h6>
      </div>
      <div
        v-if="getRezConnection"
        class="col-12 col-md-6 col-lg-4 col-xl-3 mt-4"
      >
        <label class="info-title">{{ $t("packageInformation") }}</label>
        <h6 class="mt-2">{{ getRezCopmanyInformation.PackageRemain.Name }}</h6>
      </div>
      <div class="col-12 mt-4">
        <hr />
        <h4>
          <img width="40" src="/images/rez-logo.png" alt="" />
          {{ $t("rezzervationOpenPageTitle") }}
        </h4>
        <p>{{ $t("rezzervationOpenPageDescription") }}</p>
        <div class="mt-4 text-center">
          <a href="https://panel.rezzervasyon.com" target="_blank">
            <button class="btn btn-pill donate">
              {{ $t("connectToRezz") }}
            </button>
          </a>
        </div>
      </div>
    </div>
    <div id="language" class="bg-light card my-3 p-3">
      <h3>{{ $t("languageTitle") }}</h3>
      <p>{{ $t("languageDescription") }}</p>
      <div class="mt-4">
        <div class="option">
          <div class="form_field">
            <select
              :value="$i18n.locale"
              @input="changeLang"
              id="tag"
              class="col-12"
            >
              <option
                v-for="lang in $i18n.availableLocales"
                :key="lang"
                :value="lang"
              >
                {{ $t(`languages.${lang}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImageCropper from "../../mixins/ImageCropper";
import ConnectionRezzervasyon from "../../mixins/ConnectionRezzervasyon";
import AlertBox from "../../mixins/AlertBox";
export default {
  mixins: [ImageCropper, ConnectionRezzervasyon, AlertBox],
  computed: {
    ...mapGetters(["getSettings"]),
    userImage() {
      if (this.imgSrc !== "") {
        return this.imgSrc;
      } else return this.$api.image(this.user.imagePath);
    },
    getRezCopmanyInformation() {
      return this.$store.state.Rezervation.company;
    },
    getRezConnection() {
      return this.$store.state.Rezervation.connected;
    },
  },
  data() {
    return {
      user: {
        imagePath: "",
      },
      userPassword: {
        newPassword: "",
        oldPassword: "",
        confirmNewPassword: "",
      },
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.PUSH_TO_ELEMENT();
      },
    },
  },
  methods: {
    changeLang(e) {
      localStorage.setItem("lang", e.target.value);
      this.$i18n.locale = e.target.value;
      setTimeout(() => {
        location.reload();
      }, 250);
    },
    async UPDATE_PROFILE(e) {
      e.preventDefault();
      const reg = new RegExp(/\+[0-9]{0,14}$/);
      const check = reg.test(this.user.phone);
      if (!check)
        return this.SET_ALERT_BOX("danger", this.$t("invalidPhoneRegex"));
      this.SET_LOADING_MODAL(true);
      const response = await this.$store.dispatch("SEND_TO_UPDATE_USER", {
        ...this.user,
        newImage: null,
      });
      this.SET_ALERT_BOX(response.variant, this.$t(response.message));
    },
    async UPDATE_PROFILE_IMAGE() {
      this.SET_LOADING_MODAL(true);
      const response = await this.$store.dispatch("SEND_TO_UPDATE_USER", {
        ...this.user,
        imagePath: this.imgSrc,
        newImage: this.imgFile,
      });
      this.SET_ALERT_BOX(response.variant, this.$t(response.message));
    },
    async UPDATE_CHANGE_PASWORD(e) {
      e.preventDefault();
      if (
        this.userPassword.confirmNewPassword !== this.userPassword.newPassword
      )
        return;
      this.SET_LOADING_MODAL(true);
      const response = await this.$store.dispatch(
        "SEND_TO_UPDATE_CHANGE_PASSWORD",
        {
          oldPassword: this.userPassword.oldPassword,
          newPassword: this.userPassword.newPassword,
        }
      );
      this.SET_ALERT_BOX(response.variant, this.$t(response.message));
    },
    PUSH_TO_ELEMENT() {
      const q = this.$route.query.push;
      if (q) {
        document.getElementById(q).scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  mounted() {
    this.PUSH_TO_ELEMENT();
    this.user = { ...this.$store.state.User.activeUser };
    this.sizes = { width: 0, height: 0 };
  },
};
</script>

<style></style>
