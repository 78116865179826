<template>
  <div>
    <profile></profile>
  </div>
</template>

<script>
import Profile from "../components/Profile/index.vue";
export default {
  components: { Profile },
};
</script>

<style></style>
